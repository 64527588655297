<template>
  <div class="login-container">
    <div class="login-title">微信登录</div>
    <img :src="image_url" alt="请用微信扫码登录">
    <p>请使用微信扫码登录</p>
  </div>
</template>

<script>

import {defaultOnError, getScene, saveLoginToken, getLoginStatus} from "@/utils/HeadTool"
import {boloHost} from "@/utils/Const";

export default {
  mounted:function () {
    let that = this;
    getScene(that, "", that.onGetScene, defaultOnError);
  },

  name: "ScanLogin",

  data() {
    return {
      that: this,
      image_url: "",
      count: 0,
      is_login: false,
      is_error: false,
    };
  },

  methods: {
    onGetScene(context, scene) {
      let that = this;
      let image_url = "";
      if (process.env.NODE_ENV === 'production') {
        image_url = "https://"+ boloHost +"/pages/scanimages/" + scene + ".png";
      } else {
        image_url = "http://127.0.0.1:8000/pages/image/" + scene + ".png";
      }
      this.image_url = image_url;
      // 存储scene
      //saveLoginToken(scene)


      console.log("scene: " + scene);
      // 轮训登录态


      setInterval(function() {
        if (that.count < 60 && !that.is_login && !that.is_error) {
          getLoginStatus(that, scene, that.onLoginSuccess, that.onRequestError);
        }
      }, 2000);


    },

    onLoginSuccess(ctx, data) {

      ctx.is_login = true;
      saveLoginToken(JSON.stringify(data));
      this.$router.back();
    },

    onRequestError(ctx, err_code) {
      // ErrorMchatInvalidScene

      if (err_code === 5008) {
        return;
      }


      console.log("login ...." + err_code);
      ctx.count ++;
      ctx.is_error = true;
      if (err_code === 5010) {
        window.alert("二维码错误，请刷新重试");
      } else if (err_code === 5011) {
        window.alert("二维码过期，请刷新重试");
      } else {
        window.alert("未知错误:"+err_code);
      }


    }

  }
}


</script>

<style scoped>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
  font-family: Arial, sans-serif;
}
.login-container {
  text-align: center;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}
.login-container img {
  width: 200px;
  height: 200px;
}
.login-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}
</style>